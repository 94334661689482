<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="five"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber="waitDataTotal"></waitNumber>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
          <el-tabs class="Content1Title" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="待付费" name="1" style="height: 100%">
              <div class="flex-l" style="margin-top: 20px">
                <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/患者名称" @keyup.enter.native="Search" clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100vh - 300px)">
                <el-table-column prop="orderNum" label="订单编号"></el-table-column>
                <el-table-column prop="patientName" label="患者" align="center"> </el-table-column>
                <el-table-column prop="diagnoseResult" label="诊断" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.diagnoseResult ? scope.row.diagnoseResult : "-" }}
                  </template>
                </el-table-column>
                <el-table-column prop="bankName" label="就诊日期" align="center" width="150px">
                  <template slot-scope="scope">
                    {{ scope.row.addTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalMoney" label="总金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.totalMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150px">
                  <template slot-scope="scope">
                    <span class="primary optionBtn" @click="toCharged(scope.row)">收费</span>
                    <span class="warning optionBtn" @click="toDeleteCharged(scope.row)">取消订单</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="已付费" name="2">
              <div class="flex-l" style="margin-top: 20px">
                <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/患者名称" @keyup.enter.native="Search" clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100vh - 300px)">
                <el-table-column prop="orderNum" label="订单编号"></el-table-column>
                <el-table-column prop="patientName" label="患者" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.patientName ? scope.row.patientName : "-" }}
                  </template>
                </el-table-column>
                <el-table-column prop="diagnoseResult" label="诊断" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.diagnoseResult ? scope.row.diagnoseResult : "-" }}
                  </template></el-table-column
                >
                <el-table-column prop="totalPayMoney" label="总金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.totalPayMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="状态" align="center" width="150px">
                  <template slot-scope="scope">

                    <el-tag type="warning" size="small" v-if="scope.row.state == 0">待支付</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.state == 1">已支付</el-tag>
                    <el-tag type="info" size="small" v-if="scope.row.state == 2">支付中</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.state == 3">完成备药</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -1">已退费</el-tag>
                    <el-tag type="warning" size="small" v-if="scope.row.state == -2">退费审核中</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -3">拒绝退费</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -4">退费完成</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -5">取消订单</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.state == 5">已发货</el-tag>
                    <p v-if="scope.row.state == -3">{{ scope.row.auditRemark }}</p>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="userName" label="收费员" align="center"> </el-table-column> -->
                <el-table-column prop="paySuccessTime" label="收费日期" align="center" width="150px">
                  <template slot-scope="scope">
                    {{ scope.row.paySuccessTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200">
                  <template slot-scope="scope">
                    <span class="info optionBtn" @click="toOrderInfo(scope.row, '2')">详情</span>
                    <span class="warning optionBtn" @click="showExpress(scope.row)" v-if="scope.row.state == 5 && !scope.row.isSelef">查看物流</span>
                    <span class="primary optionBtn" @click="toRefund(scope.row)" v-if="scope.row.state == 1">退费</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="已退费" name="3">
              <div class="flex-l" style="margin-top: 20px">
                <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/患者名称" @keyup.enter.native="Search" clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100vh - 300px)">
                <el-table-column prop="orderNum" label="订单编号"></el-table-column>
                <el-table-column prop="patientName" label="患者" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.patientName ? scope.row.patientName : "-" }}
                  </template>
                </el-table-column>
                <el-table-column prop="diagnoseResult" label="诊断" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.diagnoseResult ? scope.row.diagnoseResult : "-" }}
                  </template></el-table-column
                >
                <el-table-column prop="refundTime" label="退费日期" align="center" width="160px">
                  <template slot-scope="scope">
                    {{ scope.row.refundTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalMoney" label="收费金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.totalMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="refundMoney" label="退费金额(元)" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.refundMoney.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="状态" align="center" width="150px">
                  <template slot-scope="scope">
                    <el-tag type="warning" size="small" v-if="scope.row.state == 0">待支付</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.state == 1">已支付</el-tag>
                    <el-tag type="info" size="small" v-if="scope.row.state == 2">支付中</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.state == 3">完成备药</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -1">已退费</el-tag>
                    <el-tag type="warning" size="small" v-if="scope.row.state == -2">退费审核中</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -3">拒绝退费</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -4">退费完成</el-tag>
                    <el-tag type="danger" size="small" v-if="scope.row.state == -5">取消订单</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="auditRemark" label="拒绝原因" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.auditRemark ? scope.row.auditRemark : "-" }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100px">
                  <template slot-scope="scope">
                    <span class="info optionBtn" @click="toOrderInfo(scope.row, '3')">详情</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
    <el-drawer title="物流" :visible.sync="dialogExpress" :direction="direction" wrapperClosable>
      <div class="drawer-box">
        <div class="detail-item">
          <h5 class="detail-tit">物流信息</h5>
          <div class="status">
            <!-- 0快递收件(揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收 -->
            <el-tag size="small" type="info" v-if="expressData.deliverystatus == 0">揽件中</el-tag>
            <el-tag size="small" v-if="expressData.deliverystatus == 1">在途中</el-tag>
            <el-tag size="small" v-if="expressData.deliverystatus == 2">正在派件</el-tag>
            <el-tag size="small" type="success" v-if="expressData.deliverystatus == 3">已签收</el-tag>
            <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 4">派送失败</el-tag>
            <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 5">疑难件</el-tag>
            <el-tag size="small" type="danger" v-if="expressData.deliverystatus == 6">退件签收</el-tag>
          </div>
          <p><span>快递公司名称：</span>{{ expressData.expName }}</p>
          <p><span>快递单号：</span>{{ expressData.number }}</p>
          <p><span>快递公司电话：</span>{{ expressData.expPhone }}</p>
          <template v-if="expressData.deliverystatus >= 2">
            <p><span>快递员：</span>{{ expressData.courier }}</p>
            <p><span>快递员电话：</span>{{ expressData.courierPhone }}</p>
          </template>
        </div>
        <div class="detail-item">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in expressData.list" :key="index" color="#67C23A" :timestamp="item.time | timefilters">
              <span class="status-color">{{ item.status }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import waitNumber from "../../components/waitNumber";
import { Outpatient } from "@/components/Drug/Outpatient";
import { Order } from '../../components/shoppingMall/Order'
export default {
  components: {
    intNavheader,
    waitNumber,
  },
  data() {
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var order = new Order(this.TokenClient, this.Services.shoppingMall)
    return {
      OrderDomain: order,
      drugpatientDomain: drugpatient,
      OutpatientDomain: outpatient,
      searchForm: {
        keyWord: "",
        datepicker: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      activeName: "1",
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      num: "",
      patientForm: {
        age: "",
        value: "",
        name: "",
      },
      userInfo: this.$store.state.localData.userInfo,
      RefusetableData: [],
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      waitDataTotal: 0,
      dialogExpress: false,
      expressData: {
        number: '432578454379043', //快递单号
        type: 'YUNDA', //快递公司简称
        deliverystatus: '3', //快递状态 0快递收件 (揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收
        issign: '1', //是否签收 可能不准
        expName: '韵达快递', //快递公司名称
        expSite: 'www.yundaex.com', //快递公司官网
        expPhone: '95546', //快递公司电话
        courier: '', //投递员名称
        courierPhone: '0371-61844349', // 快递员电话
        updateTime: '2022-05-31T19:20:26', //快递轨迹信息最新时间
        takeTime: '1天21小时19分', //发货到收货消耗时长 (截止最新轨迹)
        logo: 'https://img3.fegine.com/express/yd.jpg', //快递公司LOGO
        list: [
          //快递详情
          {
            time: '2022-05-31T19:20:26',
            status: '【代收点】您的快件已签收，签收人在 郑州兴达锦绣园新街坊超市店(已签收签收人凭取货码签收) 领取，投诉电话: 0371 - 61844349',
          },
        ],
      },
      direction: 'rtl',
    };
  },
  mounted() {
    let activeName = JSON.parse(sessionStorage.getItem("activeName")) + "";
    if (activeName && activeName != "null") {
      sessionStorage.removeItem("activeName");
      this.activeName = activeName;
    }
    this.getList();
    this.gettWaitData1();
  },
  methods: {
    /**
     * 查看物流
     * @param {*} item
     */
    showExpress(item) {
      var _this = this
      _this.expressData = {}
      var expressCodeStr = item.expressCode
      if (expressCodeStr) {
        _this.dialogExpress = true
        _this.OrderDomain.ExpressQuery(
          expressCodeStr,
          function (data) {
            _this.dialogExpress = true
            _this.expressData = data.data
          },
          function (err) {
            _this.$message({
              type: 'error',
              message: '快递单号错误',
            })
            console.log(err)
          }
        )
      } else {
        _this.$message({
          type: 'error',
          message: '单号无效',
        })
      }
    },
    gettWaitData1() {
      let _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    toRefund(item) {
      sessionStorage.setItem("activeName", "2");
      this.$router.push({
        path: "./ChargedOrRefund",
        query: {
          type: 2, //退费
          orderId: item.id,
        },
      });
    },
    toOrderInfo(item, activeName) {
      sessionStorage.setItem("activeName", activeName);
      this.$router.push({
        path: "./OrderInfo",
        query: {
          type: 1, //已收费
          orderId: item.id,
        },
      });
    },
    handleClick(e) {
      this.pageIndex = 1;
      this.dataTotal = 0;
      this.searchForm = {
        keyWord: "",
        datepicker: "",
      };
      this.getList();
    },
    getList() {
      switch (this.activeName) {
        case "1":
          this.notChargedList();
          break;
        case "2":
          this.chargedList();
          break;
        case "3":
          this.refundList();
          break;
      }
    },
    toCharged(item) {
      sessionStorage.setItem("activeName", "1");
      this.$router.push({
        path: "./ChargedOrRefund",
        query: {
          type: 1,
          orderId: item.id,
        },
      });
    },
    toDeleteCharged(item) {
      var _this = this;
      _this
        .$confirm("是否取消此订单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // type: 'warning'
        })
        .then(() => {
          _this.OutpatientDomain.cacleMZPrescriptionDetail(
            item.id,
            function (data) {
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
              _this.getList();
            },
            function (err) {
              console.log(err);
            }
          );
        })
        .catch(() => {});
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    notChargedList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.OutpatientDomain.DrugWaitPayMent(
        item.keyWord,
        item.startTime,
        item.endTime,
        this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    chargedList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.OutpatientDomain.DrugAlreadyPayment(
        item.keyWord,
        item.startTime,
        item.endTime,
        this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    refundList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.OutpatientDomain.DrugRefundList(
        item.keyWord,
        item.startTime,
        item.endTime,
        this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    Search(event) {
      this.pageIndex = 1;
      this.getList();
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.getData();
    },
    RefuCurrentChange(pageNum) {
      this.RefucurrentPage = pageNum;
      this.RefusepageIndex = pageNum;
      this.gettWaitData();
    },
    goDoctor() {
      if (this.userInfo.IsAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    getData() {
      var _this = this;
      let state = -999;
      _this.drugpatientDomain.getRefusePrescription(
        state,
        _this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageTotal = data.data.dataTotal;
        },
        function (error) {
          _this.tableData = [];
        }
      );
    },
    gettWaitData() {
      var _this = this;
      let state = -999;
      _this.drugpatientDomain.getWaitAuditPrescription(
        state,
        _this.RefusepageIndex,
        function (data) {
          _this.RefusetableData = data.data.results;
          _this.RefusepageTotal = data.data.dataTotal;
        },
        function (error) {
          _this.RefusetableData = [];
        }
      );
    },
    goseeDoctor(item) {
      console.log(item);
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
        },
      });
    },
    goBack() {
      this.addtoNew = false;
    },
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
  },
};
</script>
<style scoped>
.pageBox {
  text-align: center;
  margin-top: 10px;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* max-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  /* font-weight: bold; */

  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}
.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.stepInput {
  width: 60%;
  margin-right: 10px;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
::v-deep .el-pagination {
  padding: 10px 5px;
}
.drawer-box {
  padding: 30px;
}
.el-drawer {
  background-color: #ffffff !important;
  width: 550px !important;
}
.el-drawer__body {
  overflow: auto;
}
.detail-item {
  position: relative;
  margin-bottom: 20px;
}

.detail-item .detail-tit {
  font-size: 18px;
}

.detail-item > p {
  margin: 5px 0;
}

.detail-item > p span {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.detail-item .status {
  position: absolute;
  right: 5px;
  top: 5px;
}

.detail-item .status .text-orange {
  color: orange;
}

.detail-item .status .text-blue {
  color: rgb(47, 0, 255);
}

.detail-item .status p.text-danger {
  color: red;
}

.detail-item .status p.text-succes {
  color: forestgreen;
}
</style>
