let Order = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 物流信息
     * @param expressCode
     * @param success
     * @param error
     * @constructor
     */
     this.ExpressQuery = function (expressCode, success, error) {
        var url = ServiceBaseAddress + '/api/Commodity/ExpressQuery/'+ expressCode;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { Order }
